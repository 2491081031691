












































































































































































































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import api from '@/api'

@Component({
    name: "couponList",
    components: {}
})

export default class extends Vue {
    config = {
        coupon_status: {
            1: '未生效',
            2: '生效中',
            3: '已过期'
        },
        coupon_type: {
            "xjq": "现金券",
            "mjq": "满减券",
            "zkq": "折扣券",
            "jfq": "积分券",
            "jfqr": "兑换券"
        },
        coupon_online: {
            0: '下线状态',
            1:'上线状态',
            2: '上线后又下线状态',
            3: '已召回'
        },
        bond_type:{},
        belong: {
            'zhike': '直客',
            'broker': '经纪人'
        }
    }
    // 表格数据
    tableData: any = [];
    // 加载状态
    loading = false;
    // 总条数
    total = 0
    // 搜索条件
    search: any = {
        pagination: {
            page: 0, //页码，从0开始
            pageSize: 10, //每页数量
        },
    };

    getChannel() {
        api
            .post('A23006', {})
            .then(res => {
                this.config.bond_type = res.list
            })
    }


    ineffective() {
        this.$router.push({
            path: 'couponListZH'
        })
    }

    activated() {
        this.searchTable();
        this.getChannel()
    }

    // 新建
    add() {
        this.$router.push({
            path: 'createCoupon'
        })
    }


    // 搜索 search:true 重置翻页
    searchTable(search = false) {
        this.loading = true;
        if (search) {
            this.search.pagination = {
                pageSize: 20,
                page: 0,
            };
        }
        api
            .post('J27302', this.search)
            .then(res => {
                this.total = res.total;
                this.tableData = res.list;
            }).finally(() =>{
            this.loading = false;
        })
    }


    //查看
    protected toDetail(couponNumber: any) {
        this.$router.push({
            path: 'couponDetail?couponNumber=' + couponNumber
        })
    }

    // 日志
    logDialog = false
    logDetail = false
    logTable = []
  logTotal = 0
  logSearch = {
    couponNumber: '',
    pagination: {
      page: 0,
      pageSize: 10
    }
  }
    showLogDialog(item: any) {
      this.logSearch.couponNumber = item.couponNumber
        api
            .post('J27307', {couponNumber: item.couponNumber, pagination: this.logSearch.pagination})
            .then(res => {
                this.logTable = res.list
              this.logTotal = res.total
                this.logDialog = true
            })

    }
  changePage(page: number) {
    this.logSearch.pagination.page = page - 1;
    api
        .post('J27307', {couponNumber: this.logSearch.couponNumber, pagination: this.logSearch.pagination})
        .then(res => {
          this.logTable = res.list
          this.logTotal = res.total
        })
  }

    row = {}
    openDesc = false
    handleCloseLogDialog() {
        this.logDialog = false
    }
    toLogDetail(row: any) {
        this.logDetail = true
        this.row = row
    }
    CloseLogDetailDialog(){
        this.logDetail = false
    }



    // 代理情况
    linkTo(couponNumber: any) {
        this.$router.push({
            path: 'couponAgentD',
            query:{
              couponNumber:couponNumber
            }
        })
    }

    // 领用
    jumpTo(path: any, item: any) {
        this.$router.push({
            path: path,
            query: {
                couponNumber: item.couponNumber
            }
        })
    }

}
